import { AlternativeActions, InputField } from "@/components/auth";
import { AuthLayout } from "@/layouts/auth-layout";
import { AuthContext } from "@/lib/contexts/auth";

import { useStore } from "@/lib/hooks/use-store";
import { CaretRight } from "@phosphor-icons/react";
import { Button, Checkbox, Flex, Text, Link } from "@radix-ui/themes";
import { Field, FieldProps, Form, Formik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import {
  AuthCard,
  ConfirmationCallout,
  ErrorCallout,
  SubmitButton,
} from "./utils";

const signupSchema = z.object({
  email: z
    .string({
      invalid_type_error: "Invalid email",
      required_error: "Email is required",
    })
    .email(),
  accept: z.coerce.boolean(),
});

export function SignupPage() {
  const authStore = useStore(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const handleSubmit = (values: { email: string; accept: boolean }) => {
    if (!values.accept) {
      setError("You must agree to our terms & conditions");
      return;
    }
    setLoading(true);
    authStore
      .signup(values.email)
      .then(() => {
        setEmailSent(true);
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  if (emailSent) {
    return (
      <AuthLayout>
        <AuthCard>
          <ConfirmationCallout message="Welcome ! Please check your email and click on the link to complete your registration." />
        </AuthCard>
      </AuthLayout>
    );
  }

  return (
    <AuthLayout>
      <AuthCard>
        <Formik
          initialValues={{ email: "", accept: false }}
          onSubmit={handleSubmit}
          validationSchema={toFormikValidationSchema(signupSchema)}
          validateOnBlur
        >
          {({ errors, touched }) => (
            <Form onChange={() => setError(null)}>
              <Flex gap="3" direction="column">
                <ErrorCallout
                  message={error}
                  errors={errors}
                  touched={touched}
                />
                <Field
                  name="email"
                  as={InputField}
                  type="email"
                  placeholder="Email"
                  autoComplete="email"
                />
                <Flex align="center" gap="2">
                  <Field name="accept" type="checkbox">
                    {({ field, form }: FieldProps<boolean>) => {
                      return (
                        <Checkbox
                          name={field.name}
                          checked={field.checked}
                          onCheckedChange={(checked) =>
                            form.setFieldValue("accept", !!checked, false)
                          }
                        />
                      );
                    }}
                  </Field>
                  <Text size="1" color="gray">
                    I accept the{" "}
                    <Link href="https://finegrain.ai/terms" target="_blank">
                      Terms & Conditions
                    </Link>{" "}
                    of the services.
                  </Text>
                </Flex>
                <SubmitButton loading={loading} errors={errors}>
                  Create Account
                </SubmitButton>
                <Text color="gray" size="1" style={{ width: 350 }}>
                  No credit card required.
                </Text>
              </Flex>
            </Form>
          )}
        </Formik>
      </AuthCard>
      <AlternativeActions>
        <div />
        <Button
          size="2"
          variant="ghost"
          onClick={() => navigate("/signin")}
          style={{ margin: 0 }}
        >
          Sign in
          <CaretRight size={13} />
        </Button>
      </AlternativeActions>
    </AuthLayout>
  );
}
