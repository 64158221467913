import { AlternativeActions, InputField } from "@/components/auth";
import { AuthLayout } from "@/layouts/auth-layout";
import { AuthContext } from "@/lib/contexts/auth";

import { useStore } from "@/lib/hooks/use-store";
import { CaretRight } from "@phosphor-icons/react";
import { Button, Flex } from "@radix-ui/themes";
import { Field, Form, Formik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { toFormikValidate } from "zod-formik-adapter";
import {
  AuthCard,
  ConfirmationCallout,
  ErrorCallout,
  SubmitButton,
} from "./utils";

const forgetPasswordSchema = z.object({
  email: z
    .string({
      invalid_type_error: "Invalid email",
      required_error: "Email is required",
    })
    .email(),
});

export function ForgotPasswordPage() {
  const authStore = useStore(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const handleSubmit = (values: { email: string; password: string }) => {
    setLoading(true);
    authStore
      .forgotPassword(values.email)
      .then(() => {
        setEmailSent(true);
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  if (emailSent) {
    return (
      <AuthLayout>
        <AuthCard>
          <ConfirmationCallout message="Check your email for a link to reset your password." />
        </AuthCard>
      </AuthLayout>
    );
  }
  return (
    <AuthLayout>
      <AuthCard>
        <Formik
          initialValues={{ email: "", password: "" }}
          onSubmit={handleSubmit}
          validate={toFormikValidate(forgetPasswordSchema)}
        >
          {({ errors, touched }) => (
            <Form onChange={() => setError(null)}>
              <Flex gap="3" direction="column">
                <ErrorCallout
                  message={error}
                  errors={errors}
                  touched={touched}
                />
                <Field
                  name="email"
                  as={InputField}
                  type="email"
                  placeholder="Email"
                  autoComplete="email"
                />
                <SubmitButton loading={loading} errors={errors}>
                  Reset Password
                </SubmitButton>
              </Flex>
            </Form>
          )}
        </Formik>
      </AuthCard>
      <AlternativeActions>
        <div />
        <Button
          size="2"
          variant="ghost"
          onClick={() => navigate("/signin")}
          style={{ margin: 0 }}
        >
          Sign in
          <CaretRight size={13} />
        </Button>
      </AlternativeActions>
    </AuthLayout>
  );
}
