import { InputField } from "@/components/auth";
import { AuthLayout } from "@/layouts/auth-layout";
import { AuthContext } from "@/lib/contexts/auth";

import { useStore } from "@/lib/hooks/use-store";
import { Flex } from "@radix-ui/themes";
import { Field, Form, Formik } from "formik";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";
import { toFormikValidate } from "zod-formik-adapter";
import { AuthCard, ErrorCallout, SubmitButton } from "./utils";

const joinSchema = z.object({
  password: z
    .string()
    .min(6, { message: "Password must be at least 6 characters" }),
  passwordConfirm: z.string(),
});

export function JoinPage() {
  const authStore = useStore(AuthContext);
  const navigate = useNavigate();
  const { token } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = (values: {
    passwordConfirm: string;
    password: string;
  }) => {
    setLoading(true);
    authStore
      .createPassword(token!, values.password, values.passwordConfirm)
      .then(() => {
        navigate("/editor");
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <AuthLayout>
      <AuthCard>
        <Formik
          initialValues={{ password: "", passwordConfirm: "" }}
          onSubmit={handleSubmit}
          validate={toFormikValidate(joinSchema)}
        >
          {({ errors, touched }) => (
            <Form onChange={() => setError(null)}>
              <Flex gap="3" direction="column">
                <ErrorCallout
                  message={error}
                  errors={errors}
                  touched={touched}
                />
                <Field
                  name="password"
                  as={InputField}
                  type="password"
                  placeholder="Password"
                  autoComplete="new-password"
                />
                <Field
                  name="passwordConfirm"
                  as={InputField}
                  type="password"
                  placeholder="Confirm Password"
                  autoComplete="confirm-password"
                />
                <SubmitButton loading={loading} errors={errors}>
                  Save Password
                </SubmitButton>
              </Flex>
            </Form>
          )}
        </Formik>
      </AuthCard>
    </AuthLayout>
  );
}
