import { StyledCard, StyledCardHeader } from "@/components/auth";
import Logo from "@/components/logo";
import { CheckCircle } from "@phosphor-icons/react";
import { Button, Callout } from "@radix-ui/themes";
import { FormikErrors, FormikTouched } from "formik";
import { ReactNode, useMemo } from "react";

export function AuthCard({ children }: { children: React.ReactNode }) {
  return (
    <StyledCard size="2">
      <StyledCardHeader>
        <Logo height={25}></Logo>
      </StyledCardHeader>
      {children}
    </StyledCard>
  );
}

export function ConfirmationCallout({ message }: { message: string }) {
  return (
    <Callout.Root color="green">
      <Callout.Icon>
        <CheckCircle size={20} color="var(--green-10)" />
      </Callout.Icon>
      <Callout.Text>{message}</Callout.Text>
    </Callout.Root>
  );
}

export function ErrorCallout({
  message,
  errors,
  touched,
}: {
  message: string | null;
  errors: FormikErrors<any>;
  touched: FormikTouched<any>;
}) {
  const userError = useMemo(() => {
    if (message) {
      return message;
    }

    if (errors) {
      const firstKey = Object.keys(touched)[0];
      if (touched[firstKey]) {
        return errors[firstKey]?.toString();
      } else {
        return null;
      }
    }
    return null;
  }, [message, errors, touched]);

  if (!userError) return null;
  return (
    <Callout.Root color="red">
      <Callout.Text>{userError}</Callout.Text>
    </Callout.Root>
  );
}

export function SubmitButton({
  loading,
  errors,
  children,
}: {
  loading: boolean;
  errors: FormikErrors<any>;
  children: ReactNode;
}) {
  return (
    <Button
      size="3"
      type="submit"
      loading={loading}
      disabled={loading || Object.keys(errors).length > 0}
    >
      {children}
    </Button>
  );
}
